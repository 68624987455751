import React from 'react'
import {Link} from 'react-router-dom'
import {Toolbar} from 'primereact/toolbar'



export default function Menu(props) {
  let style = {marginLeft: '20px'}


  return (
    <div className="container">
      <Toolbar style={{margin: '5px 0 5px 0'}}>

        <Link style={style} to={'/vergelijken'}>Vergelijken</Link>
        <Link style={style} to={'/extra-small'}>Extra Small</Link>
        <Link style={style} to={'/sim-only/youfone'}>Youfone</Link>
        <Link style={style} to={'/'}>Fifty Fifty</Link>

      </Toolbar>
    </div>)

}