import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import './index.css'

import './App.scss'
import App from './App'

import FilterExtraSmall from '../src/components/FilterExtraSmall' // !
import FilterSmall from '../src/components/FilterSmall' //
import FilterLargeProvider  from '../src/components/FilterLargeProvider'
import FilterHome from '../src/components/FilterHome'
import Data from '../src/components/Data'
import MailPropositionToSelfPopup from '../src/components/MailPropositionToSelfPopup'

if(document.getElementById('app')) {
  ReactDOM.render(<App/>, document.getElementById('app'));
}

if(document.getElementById('react_send_to_self')) {
  ReactDOM.render(<MailPropositionToSelfPopup />, document.getElementById('react_send_to_self'));
}

if(document.getElementById('react_filter_home')) {
  ReactDOM.render(<FilterHome />, document.getElementById('react_filter_home'));
}
if(document.getElementById('react_filter_extra_small')) {
  ReactDOM.render(<FilterExtraSmall />, document.getElementById('react_filter_extra_small'));
}
if(document.getElementById('react_filter_small')) {
  ReactDOM.render(<FilterSmall />, document.getElementById('react_filter_small'));
}

if(document.getElementById('react_filter_large_provider')) {
  ReactDOM.render(<FilterLargeProvider />, document.getElementById('react_filter_large_provider'));
}
if(document.getElementById('react_result_table')) {
  ReactDOM.render( <Data />, document.getElementById('react_result_table'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
