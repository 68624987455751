import React from 'react'
import Filter from './Filter'

import * as Mins from "../enum/mins";
import * as Mbs from "../enum/mb";

import {Button} from "primereact/button";
import {Slider} from "primereact/slider";
import {Dialog} from "primereact/dialog";

import * as Speed from "../enum/speed";
import {SelectButton} from "primereact/selectbutton";
import * as Duration from "../enum/duration";
import {VelocityTransitionGroup} from "velocity-react";
import MeerFilters from "./MeerFilters";
import Verbruikshulp from './Verbruikshulp'

import {Dropdown} from 'primereact/dropdown';


class FilterHome extends Filter {

  componentDidMount() {
    super.componentDidMount();
  }

  getContent() {
    return <div style={{display: this.state.display}}>

      <div id="so-sliders" className="so-sliders">

        {/*<Dialog visible={this.state.wizzardVisible}*/}
        {/*        showHeader={false}*/}
        {/*        dismissableMask={true}*/}
        {/*        modal={true}*/}
        {/*        onHide={() => this.setState({wizzardVisible: false})}*/}
        {/*>*/}
        {/*  <Verbruikshulp*/}
        {/*    closeClicked={() => {*/}
        {/*      this.setState({wizzardVisible: false})*/}
        {/*    }}*/}
        {/*    setAdviceClicked={(mbs, speed) => {*/}
        {/*      this.setAdvice(mbs, speed)*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Dialog>*/}

        <div className="b4-row">

          {/*This is the Minutes slider*/}
          <div className="b4-col-12 b4-col-lg-6 b4-col-xl-3">
            <div className="label">Selecteer aantal belminuten/sms:</div>
            <div className="slide__background p-component p-slider-horizontal"></div>
            <Slider
              value={this.state.mins}
              onChange={(e) => {
                this.onMinsSliderChange(e)
              }}
              min={0}
              max={Mins.mins.length - 1}
              ref={this.minsRef}
              onSlideEnd={() => {
                this.onSomeFilterChanged()
              }}
            />

          </div>

          {/*This is the Mb silder*/}
          <div className="b4-col-12 b4-col-lg-6 b4-col-xl-3 slider__wrapper">

            <div className="label">
              Selecteer aantal MB's:
              {/*<span className="wizzard" onClick={(e) => this.setState({wizzardVisible: true})}>*/}
              {/*  Wat heb ik nodig?*/}
              {/*</span>*/}
            </div>
            <div className="slide__background p-component p-slider-horizontal"></div>
            <Slider
              value={this.state.mbs}
              onChange={(e) => {
                this.onMbSliderChanged(e)
              }}
              min={0}
              max={Mbs.mb.length - 1}
              ref={this.mbsRef}
              onSlideEnd={() => {
                this.onSomeFilterChanged()
              }}
            />
          </div>

          {/*This is the duration*/}
          <div className="b4-col-12 b4-col-lg-12 b4-col-xl-5">
            <div className="b4-row">
              <div className="b4-col-12 b4-col-lg-6">
                <div className="label">Selecteer contractduur:</div>
                <SelectButton multiple={true}
                              value={this.state.duration}
                              options={Duration.data}
                              onChange={(e) => this.setState({duration: e.value}, () => {
                                this.onSomeFilterChanged()
                              })}
                              className="p-selectbutton-flex"/>
              </div>
              <div className="b4-col-8 b4-col-lg-4">
                <div className="label hidden-lg">Selecteer internetsnelheid:</div>
                <div className="label hidden-md hidden-sm hidden-xs">Internetsnelheid:</div>
                <SelectButton multiple={true}
                              value={this.state.speed}
                              options={Speed.data}
                              onChange={(e) => this.setState({speed: e.value}, () => {
                                this.onSomeFilterChanged()
                              })}
                              className="p-selectbutton-minwidth"/>
              </div>
              <div className="b4-col-4 b4-col-lg-2">
                <div className="label">Provider:</div>
                <Dropdown
                  className="select-provider"
                  value={this.state.provider}
                  options={this.state.providers}
                  scrollHeight="400px"
                  onChange={(e) => {
                    this.onSelectProviderChanged(e)
                  }}
                />

              </div>
            </div>

          </div>

        </div>

        {/*Below the line*/}
        <div className="filter__result">
          <div className="b4-row">
            <div className="b4-col-lg-6">
              {this.state.data &&
              <span>We hebben <strong className="state-count-all">{this.state.data.count_all}</strong> Sim Only’s voor je gevonden</span>
              }
            </div>
            <div className="b4-col-lg-3">


            </div>
            <div className="b4-col-lg-3 hidden-xs hidden-sm">
              <Button label="Toon meer filters"
                      icon="filter"
                      iconPos="right"
                      className="p-button-secondary p-button-small pull-right"
                      onClick={this.showMeerFiltersClick}
              >
                <svg aria-hidden="true"
                     focusable="false"
                     data-prefix="fas"
                     data-icon="sliders-h"
                     role="img"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512"
                     className="svg-inline--fa fa-sliders-h fa-w-16 fa-lg">
                  <path fill="currentColor"
                        d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z"
                        className=""></path>
                </svg>
              </Button>
            </div>

          </div>
        </div>
      </div>


      {/* MEER FILTER */}
      <VelocityTransitionGroup
        enter={{animation: "slideDown"}}
        leave={{animation: "slideUp"}}>
        {this.state.showMeerFilters && <MeerFilters
          data={this.state.meerfilters}
          onCbClick={this.onCheckboxFilterClicked}
          onCloseClick={this.showMeerFiltersClick}
        />}
      </VelocityTransitionGroup>

    </div>

  }

  render() {

    return <VelocityTransitionGroup
      runOnMount={true}
      enter={{animation: "fadeIn", duration: 500}}
      leave={{animation: "fadeOut"}}
    >
      {this.getContent()}
    </VelocityTransitionGroup>

  }
}

export default FilterHome
