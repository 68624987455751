import React from 'react';
import axios from 'axios';


import FilterContext from "../context/FilterContext";

class Data extends React.Component {

  static contextType = FilterContext;

  subscriptionId = null;
  state = {
    html: null,
    rows: null,
    count_all: null,
    //
    sort: null,
    limit: null,

    bms_edit: null,
  }

  constructor(props) {
    super(props);


    /**
     * Tell the world we exist so we can call all functions here outside of react
     */
    window.so_react_data = this

    this.handleData = this.handleData.bind(this)
    this.callServer = this.callServer.bind(this)
    this.sortClicked = this.sortClicked.bind(this)
    this.showAllClicked = this.showAllClicked.bind(this)
    this.bindStuff = this.bindStuff.bind(this)

  }

  // This one can be callled from outside react with 'window.so_react_data.bindStuff()';
  // This is done on the PHP server side render of the Result table
  // After an ajax call its called from within this
  bindStuff() {

    let RT = window.document.getElementsByClassName('so-result-data')[0]

    // Handle sort
    var cols = RT.getElementsByClassName('sortheader__item')
    for (var i = 0; i < cols.length; i++) {
      cols[i].addEventListener('click', (e) => {
        let col = e.currentTarget.getAttribute('class').split('-')[2]
        this.sortClicked(col)
      },true)
    }

    // Handle show all
    let sa = RT.getElementsByClassName('show-all')
    if(sa.length){
      sa[0].addEventListener('click', () => {
        this.showAllClicked()
      })
    }

    // Handle count
    var so_sliders = window.document.getElementById('so-sliders');
    
    if(so_sliders){
      // Get the count from the pre PHP rendered HTML
      var count = RT.getElementsByClassName('state-count-all')[0].innerHTML.split(' ')[0];
      // set the count in the sliders
      var ca = so_sliders.getElementsByClassName('state-count-all')
      if(ca.length) {
        ca[0].innerHTML = count
      }
    }
  }

  componentDidMount() {
    // Subscribe to the sliders
    this.subscriptionId = this.context.filterSubscribe(this.handleData)
  }

  componentWillUnmount() {
    this.context.filterUnsubscribe(this.subscriptionId)
  }

  // Finds a wrapper in the dom and sets it to display:block
  unhideResultWrapper() {
    let elem = window.document.getElementById('so-server-side-rendered-result-table');
    if (elem) {
      elem.parentNode.removeChild(elem);
    }

    elem = window.document.getElementById('react-result-container');
    if (elem) {
      elem.style.display = 'block';
    }

  }

  sortClicked(col) {

    let {sort} = this.state

    if (sort && col === sort.col) {
      // Same col switch sort (need sort && here cuz of pageSSR)
      var dir = (sort.dir === 'asc' ? 'desc' : 'asc')
    } else {
      // When !sort we're called frm $ (jQuery) and are server side rendered.
      if (!sort) {
        // The default col is sub_sub so when that one is hit desc
        dir = (col === 'sum_sub' ? 'desc' : 'asc')
      } else {
        dir = 'asc';
      }

    }
    // And pass to filter
    this.context.onResultTableClicked('sort', {col, dir});

  }

  showAllClicked() {
    // Pass to filter
    this.context.onResultTableClicked('show_all', true);
  }

  /**
   * This one is fired when a slider or some other stuff changes ( see componentDidMount() )
   * @param filterRequest
   */
  handleData(filterRequest, defaultData) {

    if (defaultData) {
      this.setState({
        rows: defaultData.rows,
        count_all: defaultData.count_all,
        sort: filterRequest.sort, // remember the final request
        limit: filterRequest.limit,
        bms_edit: filterRequest.bms_edit
      }, () => {
        // And tell everybody the result who listens
        // Unhide possible container(s)
        this.unhideResultWrapper()
        // Tell the world and tell its the default data
        this.context.onDataChanged(this.state, true)
      })
    } else {
      this.callServer(filterRequest);
    }

  }

  callServer(filterRequest) {

    var meerfilters = [];


    // Loop and get the checked ones
    filterRequest.meerfilters.forEach((a) => {
      a.data.forEach((b) => {
        if (b.checked) {
          meerfilters.push(b.id)
        }
      })
    });

    var providers = filterRequest.provider !== 0 ? [filterRequest.provider] : [];

    var finalRequest = {
      meerfilters,
      providers,
      speed: filterRequest.speed,
      duration: filterRequest.duration,
      mins: filterRequest.mins,
      mbs: filterRequest.mbs,
      locationPathname: filterRequest.locationPathname,
      limit: filterRequest.limit,
      lastSlider: filterRequest.lastSlider,
      sort: filterRequest.sort
    };

    axios.post(
      process.env.REACT_APP_SIMONLY_BACKEND + '/react/result-table', {data: finalRequest}).then(

      (response) => {
        this.setState({
          html: response.data,
          sort: finalRequest.sort, // remember the final request
          limit: finalRequest.limit
        }, () => {

          // Unhide possible container(s) !! ORDER HERE IS IMPORTANT !!
          this.unhideResultWrapper()
          // Need to attach clicks etc
          this.bindStuff()

          // And tell everybody the result who listens
          this.context.onDataChanged(this.state, false)
        })
      }
    ).catch((error) => {
      console.log(error);
    })

  }

  render() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.state.html}}></div>
    )
  }

}

export default Data