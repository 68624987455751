import React from 'react'

import FilterExtraSmall from '../components/FilterExtraSmall'
import MailPropositionToSelfPopup from '../components/MailPropositionToSelfPopup'

import Menu from './Menu'



class ExtraSmall extends React.Component {

  render() {
    return (
      <div>

        <MailPropositionToSelfPopup ref={this.MailPropositionToSelfPopup}/>

        <Menu/>
        <div className="container">
          <div className="row">
            <div className="hidden-xs col-sm-6 col-md-8" style={{backgroundColor: '#eee', height: '300px'}}>

            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <FilterExtraSmall/>
            </div>

          </div>
        </div>
      </div>
    )
  }
  

}

export default ExtraSmall
