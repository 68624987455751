
// Below the PHP!! values ... this is NO code, just as example

// ['value' => 0, 'label' => 'Geen'],
// ['value' => 500, 'label' => '500 min'],
// ['value' => 1000, 'label' => '1000 min'],
// ['value' => 1500, 'label' => '1500 min'],
// ['value' => 3000, 'label' => '3000 min'],
// ['value' => 5000, 'label' => '5000 min'],
// ['value' => 10000, 'label' => '10000 min'],
// ['value' => 999999, 'label' => 'Onbeperkt']

var mins = []

// !! is the INDEX of the array !! not the mark
const byIndex = (index) => {

  // prevent out of bounds
  if(!mins[index]){
    index = index < 0 ? 0 : mins.length - 1
  }

  return mins[index]
}

var set = (d) => mins = d;

export  {set, mins, byIndex}


