import React from 'react'
import axios from 'axios'
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import {Checkbox} from 'primereact/checkbox';
import * as EmailValidator from 'email-validator';
import {toEuro} from '../services/money'

class MailPropositionToSelfPopup extends React.Component {

  /**
   * The Thank you content
   */
  getThankyou() {
    return (
      <div class="row">
        <div className="col-xs-12">
          Email verstuurd
        </div>
        <div className="col-xs-12">
          <Button style={{width: '5em'}}
                  label="Close"
                  className="p-button-secondary"
                  onClick={() => this.close()}/>
        </div>
      </div>
    )
  }

  /**
   * De main content
   */
  getContent() {
    let {subscription, proposition, tenant, maandelijks, eenmalig} = this.state.data;

    return (

      <div>
        {/*Image and name*/}
        <div className="row">
          <div className="col-xs-12 col-md-2">
            <img src={process.env.REACT_APP_SIMONLY_BACKEND + '/img/providers/logo-' + tenant.slug + '.png'} alt=""/>
          </div>
          <div className="col-xs-12 col-md-10">
            <h5>
              {subscription.name}
            </h5>
            <span>Contractduur {proposition.duration}</span>
          </div>
        </div>

        <hr/>

        {/*Price block*/}
        <div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-xs-8 col-md-4">
            Totaal per maand
          </div>
          <div className="col-xs-4 col-md-2">
            <span style={{float: 'right'}}>
              {toEuro(maandelijks)}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-xs-8 col-md-4">
            + Eenmalige kosten
          </div>
          <div className="col-xs-4 col-md-2">
            <span style={{float: 'right'}}>
              {toEuro(eenmalig)}
            </span>
          </div>
        </div>

        {/*Mins sms etc*/}
        <div className="row" style={{marginTop: '1em'}}>
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-xs-12 col-md-10">
            {subscription.mins} minuten
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-xs-12 col-md-10">
            {subscription.sms} sms
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-xs-12 col-md-10">
            {subscription.mb !== 0 &&
            `${subscription.mb} mb`}
          </div>
        </div>


        {/*Email*/}
        <div className="row" style={{marginTop: '1em'}}>
          <div className="col-xs-12 col-md-2">
            <label>E-mail:</label>
          </div>
          <div className="col-xs-12 col-md-10">
            <InputText style={{width: '100%'}}
                       ref={this.emailRef}
                       value={this.state.email}
                       onChange={(e) => this.emailChanged(e.target.value)}/>
            {/*Constraint warning on e-mail*/}
            <span style={{
              fontSize: '0.8em',
              color: 'red',
              visibility: (this.state.isDirty && !this.state.emailValid ? 'visible' : 'hidden')
            }}>
                  Geen geldige email
            </span>

          </div>
        </div>

        {/*Algemene voorwaarden*/}
        <div className="row" style={{marginTop: '1em'}}>
          <div className="col-xs-12 col-md-2">
          </div>
          <div className="col-xs-12 col-md-10">
            <label htmlFor="cb_aav"
                   style={{fontSize: '0.8em', fontWeight: 'normal', color: (this.state.isDirty && !this.state.algemeneVoorwaarden ? 'red' : '')}}>
                  Ik accepteer de algemene voorwaarden.
            </label>
            <Checkbox inputId="cb_aav"
                      style={{float: 'right'}}
                      onChange={e => this.algemeneVoorwaardenChanged(e.checked)}
                      checked={this.state.algemeneVoorwaarden}/>
          </div>
        </div>


        {/*Buttons*/}
        <div className="row" style={{marginTop: '1em'}}>
          <div className="col-xs-12">
            <Button style={{width: '5em'}}
                    label="Cancel"
                    className="p-button-secondary"
                    onClick={() => this.close()}/>

            <Button
              label="OK"
              style={{float: 'right', width: '5em'}}
              className={(this.state.emailValid && this.state.algemeneVoorwaarden)
                ? 'p-button-success'
                : 'p-button-secondary'}
              onClick={() => this.submit()}/>
          </div>
          <div className="col-xs-12" style={{fontSize: '0.8em', marginTop: '0.5em'}}>
            Stuur dit abonnement naar mijzelf
          </div>

        </div>

      </div>


    );

  }

  render() {

    if (!this.state.data) return null;

    return (
      <Dialog
        className={'mail-proposition-to-self'}
        closeOnEscape={true}
        visible={this.state.visible}
        closable={false}
        style={{width: '90%', maxWidth: '40em'}}
        modal={true}
        onHide={() => this.close()}>
        {this.state.emailSent ? this.getThankyou() : this.getContent()}
      </Dialog>

    );

  }

  default_state = {
    visible: false,
    data: false,
    email: '',
    algemeneVoorwaarden: false,
    emailValid: false,
    emailSent: false,
    isDirty: false
  };

  constructor(props) {
    super(props);

    this.state = Object.assign({},this.default_state);

    /**
     * Tell the world we exist so we can call all functions here outside of react
     */
    window.so_react_mail_to_self = this;
    this.emailRef = React.createRef();
  }

  algemeneVoorwaardenChanged(val) {
    this.setState({algemeneVoorwaarden: val})
  }

  emailChanged(val) {
    this.setState({
      email: val,
      emailValid: EmailValidator.validate(val)
    });
  }

  open(propositionId) {
    axios.get(
      process.env.REACT_APP_SIMONLY_BACKEND + '/react/get-proposition/' + propositionId).then(
      (response) => {

        this.setState({
          data: response.data,
          emailSent: false // reset when reopen
        }, () => {
          this.setState({visible: true}, () => {
            this.emailRef.current.element.focus()
          })
        })
      }
    )
  }

  close() {
    this.setState(this.default_state);
  }

  submit() {
    this.setState({'isDirty': true})

    if (this.state.emailValid && this.state.algemeneVoorwaarden) {
      axios.post(
        process.env.REACT_APP_SIMONLY_BACKEND + '/react/send-to-self', this.state).then((response) => {
        if (response.data.msg === 'OK') {
          this.setState({emailSent: true});
        }
      })
    }
  }

  escFunction(e,m){
    if(m.state.visible && e.keyCode === 27) {
      this.close();
    }
  }

  /**
   * For some reason escape is not working now in prime react
   */
  componentDidMount(){
    let m = this;
    document.addEventListener('keydown', (e) => this.escFunction(e, m), false);

  }

}

export default MailPropositionToSelfPopup