const getOnbeperkt = (value) => {

  let val = parseInt(value);

  switch(val){
    case 999999:
      return 'Onbeperkt';
    case 10000:
      return '10.000';
    case 20000:
      return '20.000';
    case 0:
      return 'Géén';
    default:
      return val;
  }


}

export {getOnbeperkt}