import React from 'react'
import Filter from './Filter'
import {Slider} from "primereact/slider";
import * as Mins from "../enum/mins";
import * as Mbs from "../enum/mb";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import Verbruikshulp from "./Verbruikshulp";
import {SelectButton} from "primereact/selectbutton";
import * as Duration from "../enum/duration";

class FilterExtraSmall extends Filter {


  render(){

    return <div className="so-sliders">

      {/*<Dialog visible={this.state.wizzardVisible}*/}
      {/*        showHeader={false}*/}
      {/*        dismissableMask={true}*/}
      {/*        modal={true}*/}
      {/*        onHide={() => this.setState({wizzardVisible: false})}*/}
      {/*>*/}
      {/*  <Verbruikshulp*/}
      {/*    closeClicked={() => {*/}
      {/*      this.setState({wizzardVisible: false})*/}
      {/*    }}*/}
      {/*    setAdviceClicked={(mbs, speed) => {*/}
      {/*      this.setAdvice(mbs, speed)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Dialog>*/}


      <div className="row">
        <div className="col-xs-12 text-center">
          <h2><b>Vind jouw Sim Only abonnement </b><br /> Check de beste deals</h2>
        </div>
        {/*This is the Minutes slider*/}
        <div className="col-xs-12">
          <div className="label">Selecteer aantal belminuten/sms:</div>
          <div className="slide__background p-component p-slider-horizontal"></div>
          <Slider
            className="fifty-fifty"
            value={this.state.mins}
            onChange={(e) => { this.onMinsSliderChange(e) }}
            min={0}
            max={Mins.mins.length - 1}
            ref={this.minsRef}
            onSlideEnd={() => { this.onSomeFilterChanged() }}
          />

        </div>

        {/*This is the Mb silder*/}
        <div className="col-xs-12">
          <div className="label">
            Selecteer aantal MB's:
            {/*<span className="wizzard" onClick={(e) => this.setState({wizzardVisible: true})}>*/}
            {/*    Wat heb ik nodig?*/}
            {/*  </span>*/}
          </div>
          <div className="slide__background p-component p-slider-horizontal"></div>
          <Slider
            value={this.state.mbs}
            className="fifty-fifty"
            onChange={(e) => { this.onMbSliderChanged(e) }}
            min={0}
            max={Mbs.mb.length - 1}
            ref={this.mbsRef}
            onSlideEnd={() => { this.onSomeFilterChanged() }}
          />
        </div>



        <div className="col-xs-12 col-sm-8">
          <div className="label">Selecteer contractduur:</div>
          <SelectButton multiple={true}
                        value={this.state.duration}
                        options={Duration.data}
                        onChange={(e) => this.setState({duration: e.value}, () => {
                          this.onSomeFilterChanged()
                        })}
                        className="p-selectbutton-flex"/>
        </div>
        <div className="col-xs-12 col-sm-4 text-center" style={{padding:'17px 0'}}>
          <Button label="Vergelijk"
                  className="p-button-success icon-arrow-right"
                  onClick={() => this.onVergelijkClick(`/vergelijken?d=${this.state.duration.join(',')}&mins=${this.state.mins}&mbs=${this.state.mbs}&ls=${this.state.lastSlider}`) } />

        </div>

      </div>
    </div>

  }
}

export default FilterExtraSmall
