import React from 'react'
import Menu from './Menu'
import FilterLargeProvider from "../components/FilterLargeProvider";
import Data from "../components/Data";


class LargeProvider extends React.Component {

  render(){
    return (
      <div>
        <Menu/>
        <div className="container">
          <div className="row">

            <div className="col-xs-12">
              <FilterLargeProvider />
            </div>
          </div>
        </div>

        <div style={{marginTop: '20px'}}>
          <Data />
        </div>

      </div>
    )
  }

}

export default LargeProvider
