import React from 'react'
import Filter from './Filter'
import {Slider} from "primereact/slider";
import * as Mins from "../enum/mins";
import * as Mbs from "../enum/mb";
import {Button} from "primereact/button";

class FilterExtraSmall extends Filter {


  render(){
    

    
    return <div className="so-sliders">

      <div className="row">

        {/*This is the Minutes slider*/}
        <div className="col-xs-12">
          <div className="label">Selecteer aantal belminuten/sms:</div>
          <div className="slide__background p-component p-slider-horizontal"></div>
          <Slider
            value={this.state.mins}
            onChange={(e) => { this.onMinsSliderChange(e) }}
            min={0}
            max={Mins.mins.length - 1}
            ref={this.minsRef}
            onSlideEnd={() => { this.onSomeFilterChanged() }}
          />

        </div>

        {/*This is the Mb silder*/}
        <div className="col-xs-12">
          <div className="label">Selecteer aantal MB's:</div>
          <div className="slide__background p-component p-slider-horizontal"></div>
          <Slider
            value={this.state.mbs}
            onChange={(e) => { this.onMbSliderChanged(e) }}
            min={0}
            max={Mbs.mb.length - 1}
            ref={this.mbsRef}
            onSlideEnd={() => { this.onSomeFilterChanged() }}
          />
        </div>
        <div className="col-xs-12">
          <Button label="Vergelijk"
                  className="p-button-success icon-arrow-right pull-right"
                  onClick={() => this.onVergelijkClick(`/vergelijken?d=&mins=${this.state.mins}&mbs=${this.state.mbs}&ls=${this.state.lastSlider}`) } />
        </div>
      </div>
    </div>

  }
}

export default FilterExtraSmall
