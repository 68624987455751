
// Below the PHP!! values ... this is NO code, just as example

// ['label' => '3G', 'value' => '3G'],
// ['label' => '4G', 'value' => '4G']

var data = [];

const indexes = (index) => {
  return  data.map( (item) => item.value )
}

var set = (d) => data = d;


export {set, data, indexes}