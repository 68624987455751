
// Below the PHP!! values ... this is NO code, just as example

// ['value' => 0, 'label' => 'Geen'],
// ['value' => 500, 'label' => '500 mb'],
// ['value' => 1000, 'label' => '1000 mb'],
// ['value' => 1500, 'label' => '1500 mb'],
// ['value' => 2000, 'label' => '2000 mb'],
// ['value' => 2500, 'label' => '2500 mb'],
// ['value' => 3000, 'label' => '3000 mb'],
// ['value' => 3500, 'label' => '3500 mb'],
// ['value' => 4000, 'label' => '4000 mb'],
// ['value' => 4500, 'label' => '4500 mb'],
// ['value' => 5000, 'label' => '5000 mb'],
// ['value' => 5500, 'label' => '5500 mb'],
// ['value' => 6000, 'label' => '6000 mb'],
// ['value' => 6050, 'label' => '6500 mb'],
// ['value' => 7000, 'label' => '7000 mb'],
// ['value' => 7050, 'label' => '7500 mb'],
// ['value' => 8000, 'label' => '8000 mb'],
// ['value' => 8050, 'label' => '8500 mb'],
// ['value' => 9000, 'label' => '9000 mb'],
// ['value' => 9050, 'label' => '9050 mb'],
// ['value' => 10000, 'label' => '10000 mb'],
// ['value' => 999999, 'label' => 'Onbeperkt']

var mb = []
// !! is the INDEX of the array !! not the mark
const byIndex = (index) => {
  // prevent out of bounds
  if(!mb[index]){
    index = index < 0 ? 0 : mb.length - 1
  }
  return mb[index]
}

// You move in speed, say 666 and it will return 2
// (breaks at 1000, and index of that is 2)
// This one is used by the 'wizzard'
const getUpperIndexBySpeed = (speed) => {

  for(var i = 0; i<mb.length -1; i++){
    if(mb[i].value >= speed){
      break;
    }  
  }
  return i;
}

var set = (d) => mb = d;
export  {set, mb, byIndex,getUpperIndexBySpeed}


