import React from 'react'
import {Button} from 'primereact/button'
import * as Mb from '../enum/mb'


class Verbruikshulp extends React.Component {

  render() {
    return (
      <div className="so-verbruikshulp">
        <button className="overlay__close" onClick={this.closeClicked}>
          <span>Sluiten</span>
        </button>
        <div className="overlay__title">Verbruikshulp mobiel internet</div>
        <div className="overlay__text">
          <p>
            Schat in hoeveel MB’s je nodig hebt bij je abonnement
          </p>
        </div>
        <div className="overlay__questions">
          <p>
            <strong>Hoe vaak ben jij op je telefoon aan het..</strong>
          </p>

          {this.getQuestions( 'all_but_last' )}
        </div>
        <div>
          {this.getQuestions( 'last' )}
        </div>

        <div className="overlay__advice" style={{visibility: `${this.state.advies.show ? 'visible' : 'hidden'}`}}>
          Ons advies voor jou: <strong>{this.state.advies.mb}</strong> MB met <strong>{this.state.advies.speed}</strong>
        </div>
        <div className="overlay_progress_bar">
          <span style={{width: this.getPercentage()+'%'}}></span>
        </div>
        <div className="overlay__button-container">
          <Button label="Neem advies over"
                  className="p-button-success p-button-medium pull-right"
                  onClick={this.setAdviceClicked}
                  disabled={!this.state.advies.show}
          />
        </div>

      </div>
    )
  }

  getPercentage(){
    return  parseInt ((this.state.advies.mb / this.state.sum_max_anwser) * 100);
  }

  getQuestions(type ) {

    return this.state.q_and_a.map((q, i, arr) => {

      if(
        (type === 'all_but_last' && i < (arr.length - 1)) ||  // return all but last
        (type === 'last' && i === (arr.length - 1))           // return only last
      ) {
        return (
          <div className="row" key={i}>
            <div className="col-md-5"><span className="overlay__q">{q.q}</span></div>
            <div className="col-md-7">
              <ul className="overlay__anwsers">
                <li data-xy={i + '_0'} className={q.a[0].checked ? 'checked' : ''} onClick={this.anwserClicked}>nooit</li>
                <li data-xy={i + '_1'} className={q.a[1].checked ? 'checked' : ''} onClick={this.anwserClicked}>af en toe</li>
                <li data-xy={i + '_2'} className={q.a[2].checked ? 'checked' : ''} onClick={this.anwserClicked}>regelmatig</li>
                <li data-xy={i + '_3'} className={q.a[3].checked ? 'checked' : ''} onClick={this.anwserClicked}>vaak</li>
                <li data-xy={i + '_4'} className={q.a[4].checked ? 'checked' : ''} onClick={this.anwserClicked}>heel vaak</li>
              </ul>
            </div>
          </div>
        )
      } else {
        return null;
      }
    })

  }

  state = {

    advies: {
      show: false,
      mb_index: null,
      mb: null,
      speed_index: null,
      speed_mb: null,
      speed: null
    },
    // Questions and anwsers
    q_and_a: [

      {q: 'Facebooken (lezen en posten)', a: [0, 80, 160, 240, 320]},
      // the g_3_4 prop defines at what index we'll advise 4G
      {g_3_4: 3, q: 'Muziek luisteren (bijv. Spotify en online radio)', a: [0, 250, 500, 750, 1000]},
      {g_3_4: 2, q: 'Films / video’s kijken (bijv. YouTube en Netflix)', a: [0, 500, 1000, 1500, 2000]},
      {q: 'Navigeren (bijv. Google Maps)', a: [0, 15, 30, 45, 60]},
      {q: 'E-mailen', a: [0, 20, 40, 60, 80]},
      {q: 'Websites bezoeken / nieuws lezen', a: [0, 70, 140, 210, 280]},
      {q: 'Chatten via app (bijv. Whatsapp)', a: [0, 3, 6, 9, 12]},
      {q: 'Foto’s posten(bijv.via Whatsapp of Instagram', a: [0, 15, 30, 45, 60]},
      {q: 'Spelletjes spelen via app (bijv. Candycrush)', a: [0, 20, 40, 60, 80]},
      // the discount prop tells the % of the total so say 1000 mb 85 should give you 150
      {discountWifi: true, q: 'Hoe vaak gebruik je een WiFi-netwerk? (bijv. thuis of op het werk)', a: [0, 25, 45, 70, 85]},

    ],
    sum_max_anwser: 0
  }


  closeClicked(){
    this.props.closeClicked()
  }
  setAdviceClicked(){
    this.props.setAdviceClicked(this.state.advies.mb, this.state.advies.speed)
    this.props.closeClicked()
  }
  /**
   * When an answer is clicked
   */
  anwserClicked(e) {
    // Get cooordinates
    let t = e.target.getAttribute('data-xy').split('_')
    let x = parseInt(t[1]);
    let y = parseInt(t[0]);
    // Clone
    let q_and_a = [...this.state.q_and_a]
    // get to cell
    let cell = q_and_a[y].a[x]
    // calculate new state
    let newState = !cell.checked
    // empty entire row (easy way)
    q_and_a[y].a.forEach((e) => {
      e.checked = false
    })
    // And move in new state
    cell.checked = newState

    this.setState({
        q_and_a
      },
      this.generateAdvice()
    )
  }

  /**
   * Her is the advice calculated
   */
  generateAdvice() {
    // We're interested in these
    let mbTotal = 0
    let discountWifi = 0
    let g_3_4 = null

    // Loop through them all
    this.state.q_and_a.forEach((question) => {
      question.a.forEach((anwser, i) => {
        // When is checked
        if (anwser.checked) {
          // Have discount?
          if (question.discountWifi) {
            discountWifi = anwser.v
          } else {
            // Need 4g? ... i is the answer index (stop with the first 'positive')
            if(!g_3_4 && question.g_3_4 && i >= question.g_3_4){
              g_3_4 = '4G'
            }
            // Or usaeg questions
            mbTotal += anwser.v
          }
        }
      })
    })

    // calculate the discount
    let withDiscount = parseInt(mbTotal * (100 - discountWifi) / 100)
    // Get the first slider index
    let mb_index = Mb.getUpperIndexBySpeed(withDiscount)

    this.setState({
      advies: {
        show: mb_index > 0, // Wanneer niet 0
        mb_index: mb_index, // slider index
        mb: Mb.byIndex(mb_index).value, // slider value
        speed: g_3_4 ? g_3_4 : '3G'
      }
    })
  }


  constructor(props) {
    super(props);
    // Manipulate the initial state so we can check fields
    // This is a bit ugly.. I know


    // Also get the sum of all max speeds
    let sum = 0;
    this.state.q_and_a.forEach((question) => {
      sum += question.a[question.a.length-1];
      question.a.forEach((anwser, i) => {
        question.a[i] = {v: anwser}
      })
    })

    this.state.sum_max_anwser = sum;

    this.closeClicked = this.closeClicked.bind(this)
    this.setAdviceClicked = this.setAdviceClicked.bind(this)
    this.anwserClicked = this.anwserClicked.bind(this)

  }

}

export default Verbruikshulp


