import React, {Component} from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'


import Home from './pages/Home'
import ExtraSmall from './pages/ExtraSmall'
import LargeProvider from './pages/LargeProvider'
import FiftyFifty from './pages/FiftyFifty'

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route key="00" exact path="/vergelijken" render={(props) => <Home {...props}/>}/>
            <Route key="03" exact path="/extra-small" render={(props) => <ExtraSmall {...props}/>}/>
            <Route key="04" exact path="/sim-only/youfone" render={(props) => <LargeProvider {...props}/>}/>
            <Route key="04" exact path="/" render={(props) => <FiftyFifty {...props}/>}/>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
