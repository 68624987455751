import React from 'react';

class MeerFilters extends React.Component {


  getFilterItems(data) {

    let final = [];
    data.forEach((column, index) => {

      final.push(
        <div key={index} className="b4-col-md-6 b4-col-lg-3">
          <div className="widget-title">{column.header}</div>
          {column.data.map((item, i) => {
            return (
              <div key={i} className="filter-item">
                <input
                  type="checkbox"
                  id={item.id}
                  checked={item.checked ? true : false}
                  onChange={(e) => {
                    this.props.onCbClick(e)
                  }}
                />
                <label htmlFor={item.id}>{item.label}</label>
              </div>
            )
          })}
        </div>)
    })

    return final;

  }

  render() {
    if (!this.props.data) return null;

    return (
      <div className="filter__options">

        <div className="b4-row">
          {this.getFilterItems(this.props.data)}
        </div>

        <button className="filter__close" onClick={this.props.onCloseClick}><strong>filter inklappen</strong></button>
      </div>
    )
  }


}

export default MeerFilters
