import React from 'react'
import FilterSmall from "../components/FilterSmall";
import Menu from './Menu'
class FiftyFifty extends React.Component {

  render(){
    return (
      <div>
      <Menu/>
      <div className="container">
        <div className="row">
          <div className="hidden-xs col-sm-6 col-md-6" style={{backgroundColor:'#eee',height:'300px'}}> </div>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <FilterSmall />
          </div>

        </div>
      </div>
      </div>
    )
  }

}

export default FiftyFifty
