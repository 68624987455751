
// Below the PHP!! values ... this is NO code, just as example

// ['label' => '1 Maand', 'value' => 1],
// ['label' => '1 Jaar', 'value' => 12],
// ['label' => '2 Jaar', 'value' => 24]

var data = [];

const indexes = (index) => {
  return  data.map( (item) => item.value )
}

var set = (d) => data = d;

export {set, data, indexes}