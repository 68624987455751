import React from 'react'
import QS from 'query-string'
import FilterContext from '../context/FilterContext'
import {getOnbeperkt} from '../services/onbeperkt'
import * as Mbs from '../enum/mb'
import * as Mins from '../enum/mins'
import * as Speed from '../enum/speed'
import * as Duration from '../enum/duration'
import axios from "axios";


class Filter extends React.Component {

  static contextType = FilterContext;

  datasubscribeId = null;
  clicksubscribeId = null;

  /* Default state */
  state = {
    // Sliders and buttons
    speed: null,
    duration: null,
    mins: null,
    mbs: null,
    locationPathname: null,
    limit: null,
    sort: null,
    lastSlider: null,
    //
    showMeerFilters: false,
    showProviderFilter: false,
    meerfilters: null,
    providers: null,
    provider: 0,
    wizzardVisible: false,
    display: 'none',

    // This is filled through the context callback call ( see Filter Context )
    // and is the data that we get for the ResultTable
    data: null,
    nodata: null,
    defaultLimit: null,
    bms_edit: null,
  }

  constructor(props) {
    super(props)

    // Some refs to move some text into the slider
    this.minsRef = React.createRef();
    this.mbsRef = React.createRef();

    this.onSomeFilterChanged = this.onSomeFilterChanged.bind(this)
    this.onVergelijkClick = this.onVergelijkClick.bind(this)
    this.onCheckboxFilterClicked = this.onCheckboxFilterClicked.bind(this)
    this.onSelectProviderChanged = this.onSelectProviderChanged.bind(this)
    this.showMeerFiltersClick = this.showMeerFiltersClick.bind(this)
    this.showProviderFilterClick = this.showProviderFilterClick.bind(this)

  }

  componentWillUnmount() {

    this.context.dataUnsubscribe(this.datasubscribeId)
    this.context.clickUnsubscribe(this.clicksubscribeId)
  }

  onVergelijkClick(jumpTo) {


    if (jumpTo) {
      // And jump home
      window.document.location.href = jumpTo
    } else {

      // nodata's lifecycle 'no-data' => true => false
      if (this.state.nodata === true) {
        this.setState({nodata: false}, () => {
          this.onSomeFilterChanged()
        })
      }
    }
  }

  // Got from Verbruikshulp
  setAdvice(mbs, speed) {
    // We can have an advice that does not exists.. take the upper one
    let mbsIndex = Mbs.getUpperIndexBySpeed(mbs)
    // Set the value inside the slider
    let handle = this.mbsRef.current.el.querySelector('.p-slider-handle')
    handle.innerHTML = Mbs.byIndex(mbsIndex).value;

    // And set the position of the slider and the 3G or 4G speed
    this.setState({speed: [speed], mbs: mbsIndex},
      () => this.onVergelijkClick()
    );
  }

  componentDidMount() {
    // Subscribe to the data (ResultTable component)
    // At the moment the only thing were interested in is the count
    // but I save all anyway
    this.datasubscribeId = this.context.dataSubscribe((data, initial) => {

      if (!initial) {
        // For Google Tag Manager.. Fire an event after a reload
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'proposition.loaded'
        });
      }

      this.setState({data: data});

    })

    //  Subscribe to the clicks in the Result table
    this.clicksubscribeId = this.context.clickSubscribe((type, data) => {

      switch (type) {
        case 'show_all':
          this.setState({limit: 999999}, () => this.onSomeFilterChanged())
          break;
        case 'sort':
          this.setState({sort: data}, () => this.onSomeFilterChanged())
          break;
        default:
          console.log('Unknown click...');
      }
    })

    // Get the config from them server.
    axios.post(process.env.REACT_APP_SIMONLY_BACKEND + '/react/config', {
      locationPathname: document.location.pathname,
      query: window.location.search ? QS.parse(window.location.search, {parseNumbers: true}) : null
    })
      .then((response) => {
          // Set the enums
          Speed.set(response.data.speed)
          Mins.set(response.data.mins)
          Duration.set(response.data.duration)
          Mbs.set(response.data.mbs)

          this.setState({
            meerfilters: response.data.meerfilters,
            providers: response.data.providers,
            mins: response.data.defaultMinsIndex,
            mbs: response.data.defaultMbsIndex,
            locationPathname: window.location.pathname,
            lastSlider: response.data.lastSlider,
            duration: response.data.defaultDurations,
            speed: response.data.defaultSpeeds,
            limit: response.data.defaultLimit,
            defaultLimit: response.data.defaultLimit,
            sort: response.data.defaultSort,
            data: response.data.defaultData,
            nodata: (response.data.defaultData === 'no-data'),
            bms_edit: response.data.bms_edit,
            display: 'block'
          }, () => {
            // Should set the default sliders
            this.minsSliderChanged(this.state.mins);
            this.mbSliderChanged(this.state.mbs);
            // and when done fire the first query !! with default data so only 1 call !!

            // NOW IS SERVER SIDE RENDERED SO NO DEFAULT FIRE UNLESS YOUR DEVVING //
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              this.onSomeFilterChanged();
            }
          })
        }
      ).catch((error) => {
      console.log(error);
    })
  }

  onSelectProviderChanged(e) {

    this.setState({provider: e.value}, function () {
      this.onSomeFilterChanged()
    })
  }

  onCheckboxFilterClicked(e) {

    // make copy
    let mf = [...this.state.meerfilters];

    // Find col and set checked / uncheck
    mf.forEach((column, i) => {
      column.data.forEach((checkbox, j) => {
        if (e.target.id === checkbox.id) {
          mf[i].data[j].checked = e.target.checked;
        }
      })
    })

    this.setState({meerfilters: mf}, function () {
      this.onSomeFilterChanged()
    })
  }

  minsSliderChanged(value) {
    if (!this.minsRef.current) return

    let myMins = Mins.byIndex(value).value
    let handle = this.minsRef.current.el.querySelector('.p-slider-handle')
    handle.innerHTML = getOnbeperkt(myMins)
  }

  mbSliderChanged(value) {
    if (!this.mbsRef.current) return
    let myMbs = Mbs.byIndex(value).value
    let handle = this.mbsRef.current.el.querySelector('.p-slider-handle')
    handle.innerHTML = getOnbeperkt(myMbs)
  }

  showMeerFiltersClick() {
    this.setState({showMeerFilters: !this.state.showMeerFilters})
  }

  showProviderFilterClick() {
    this.setState({showProviderFilter: !this.state.showProviderFilter})
  }

  onMinsSliderChange(e) {
    if (e.value !== this.state.mins) {
      this.setState({mins: e.value, lastSlider: 'mins'}, () => {
        this.minsSliderChanged(e.value)
      })
    }
  }

  onMbSliderChanged(e) {
    if (e.value !== this.state.mbs) {
      this.setState({mbs: e.value, lastSlider: 'mbs'}, () => {
        this.mbSliderChanged(e.value)
      })
    }
  }

  onSomeFilterChanged(defaultData) {
    // We can get a 'no-data' from the ReactConfig from the server
    // When so we set state.nodata to true.
    // It means we do nothing with result data UNTIL this.state.nodata
    // is set to false
    if (this.state.nodata) {
      return;
    }


    // Tell the world i'm changed (or the default data is coming in)
    this.context.onFilterChanged(this.state, defaultData)
  }

}

export default Filter
