import React from 'react';
import uuid from 'uuid/v4';

const FilterContext = React.createContext(
  {

    filterSubscribers: [],
    dataSubscribers: [],
    clickSubscribers: [],

    /*******************************************************************************************************************
     *                          SUBSCRIBE CALLS                                                                        *
     ******************************************************************************************************************/

    /** Filter changes: You'll get the filter settings on a change */
    filterSubscribe: function (callback) {
      let id = uuid()
      this.filterSubscribers[id] = {callback: callback}
      return id
    },

    filterUnsubscribe: function (id) {
      delete this.filterSubscribers[id]
    },

    /** Data You'll get the vergelijker result  */
    dataSubscribe: function (callback) {
      let id = uuid()
      this.dataSubscribers[id] = {callback: callback}
      return id
    },

    dataUnsubscribe: function (id) {
      delete this.dataSubscribers[id]
    },
    /** Click: You'll get the clicks on/in the result table */
    clickSubscribe: function (callback) {
      let id = uuid()
      this.clickSubscribers[id] = {callback: callback}
      return id
    },

    clickUnsubscribe: function (id) {
      delete this.clickSubscribers[id]
    },



    /*******************************************************************************************************************
     *                          EVENTS                                                                                 *
     ******************************************************************************************************************/
    onResultTableClicked: function(key,val){

      // Notify subscribers of event.
      Object.values(this.clickSubscribers).forEach((subscriber) => {
        subscriber.callback(key,val);
      })
    },


    /**
     *
     * @param data
     * @param initial If its the default data we get with the config
     */
    // onFilterChanged is called by the Data (when we have new data)
    onDataChanged: function(data, initial){
      // Notify subscribers of event.
      Object.values(this.dataSubscribers).forEach((subscriber) => {
        subscriber.callback(data, initial);
      })
    },
    
    // onFilterChanged is called by the Slider
    onFilterChanged: function (data, defaultData) {

      setTimeout(() => {
          // Notify subscribers of event.
          Object.values(this.filterSubscribers).forEach((subscriber) => {
            subscriber.callback(data, defaultData);
          })
      }, 0);

      return
    }

  }
);


export default FilterContext
