import React from 'react'
import Filter from './Filter'
import {SelectButton} from "primereact/selectbutton";
import * as Duration from "../enum/duration";
import {VelocityTransitionGroup} from "velocity-react";

class FilterLargeProvider extends Filter {


  render() {

    return <VelocityTransitionGroup
      runOnMount={true}
      enter={{animation: "fadeIn",duration:500}}
      leave={{animation: "fadeOut"}}
    >
      {this.getContent()}
    </VelocityTransitionGroup>

  }


  getContent() {
    return <div className="provider-page-filter">


          <div className="label">Contractduur:</div>
          <SelectButton multiple={true}
                        value={this.state.duration}
                        options={Duration.data}
                        onChange={(e) => this.setState({duration: e.value}, () => {
                          this.onSomeFilterChanged()
                        })}
          />
        </div>

  }
}

export default FilterLargeProvider
